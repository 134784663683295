import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const UserRegisterForSMSFEngagement = () => {
  return (
    <Layout>
      <Seo
        title="How to Sign Up for Entity Registration/Engagement from Inside Profile?"
        description={`This is a guide for users who want to make a profile from inside the Synkli app. This manual will help you guide through the entire process.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`sign-up-for-entity-registration-engagement-from-inside-profile`} />
    </Layout>
  )
}

export default UserRegisterForSMSFEngagement
